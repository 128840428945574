import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { Button as ButtonMui, CircularProgress } from '@mui/material';
import { ButtonStyledProps } from './types';
import COLORS from '@src/constants/colors';
const StyledButton = styled(ButtonMui, {
  shouldForwardProp: props => props !== 'dark' && props !== 'rounded'
})<ButtonStyledProps>(({
  rounded,
  variants
}) => ({
  padding: '6px 24px',
  textTransform: 'none',
  ...(variants === 'secondary' && {
    background: 'transparent',
    color: COLORS.blue[400],
    border: `1px solid ${COLORS.blue[400]}`,
    '&.Mui-disabled': {
      color: COLORS.blue[400],
      opacity: '30%'
    }
  }),
  ...(variants === 'secondary-2' && {
    background: 'transparent',
    '&.MuiButton-root': {
      position: 'relative',
      background: `linear-gradient(90deg, ${COLORS.blue[400]} 0%, ${COLORS.indigo[500]} 100%)`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    '&.MuiButton-root::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      borderRadius: '5px',
      padding: '1px',
      background: `linear-gradient(90deg, ${COLORS.blue[400]} 0%, ${COLORS.indigo[500]} 100%)`,
      WebkitMask: `linear-gradient(${COLORS.white} 0 0) content-box, linear-gradient(${COLORS.white} 0 0)`,
      WebkitMaskComposite: 'xor',
      maskComposite: 'exclude'
    },
    '&.Mui-disabled': {
      color: COLORS.blue[400],
      opacity: '30%'
    }
  }),
  ...(variants === 'secondary-no-border' && {
    background: 'transparent',
    color: COLORS.blue[400],
    border: '1px solid inherit',
    '&.Mui-disabled': {
      color: COLORS.blue[400],
      opacity: '30%'
    }
  }),
  ...(variants === 'primary' && {
    background: `linear-gradient(92.15deg, ${COLORS.purple[400]} 0%, ${COLORS.blue[400]} 96.87%)`,
    color: COLORS.white,
    border: '1px solid inherit',
    '&.Mui-disabled': {
      color: COLORS.white,
      opacity: '25%'
    }
  }),
  ...(variants === 'error' && {
    background: 'red',
    color: COLORS.white,
    border: '1px solid inherit',
    '&.Mui-disabled': {
      color: COLORS.white,
      opacity: '25%'
    },
    '&:hover': {
      background: 'red'
    }
  }),
  ...(variants === 'primary-2' && {
    background: `linear-gradient(90deg, ${COLORS.blue[400]} 0%, ${COLORS.indigo[500]} 100%)`,
    color: COLORS.white,
    border: '1px solid inherit',
    '&.Mui-disabled': {
      color: COLORS.white,
      opacity: '25%'
    }
  }),
  ...(variants === 'popup-secondary' && {
    background: 'transparent',
    color: COLORS.white,
    border: '1px solid inherit',
    '&.Mui-disabled': {
      color: COLORS.white,
      opacity: '30%'
    }
  }),
  ...(variants === 'popup-secondary-2' && {
    background: 'transparent',
    '&.MuiButton-root': {
      position: 'relative',
      background: `linear-gradient(90deg, ${COLORS.blue[400]} 0%, ${COLORS.indigo[500]} 100%)`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent'
    },
    '&.Mui-disabled': {
      color: COLORS.blue[400],
      opacity: '30%'
    }
  }),
  ...(variants === 'with-icon' && {
    background: 'transparent',
    color: COLORS.white,
    border: `1px solid ${COLORS.white}`,
    '&.Mui-disabled': {
      color: COLORS.white,
      opacity: '30%'
    }
  }),
  ...(variants === 'green' && {
    background: COLORS.green[500],
    color: COLORS.white,
    '&.Mui-disabled': {
      color: COLORS.green[400],
      opacity: '30%'
    },
    '&:hover': {
      background: COLORS.green[500]
    }
  }),
  ...(variants === 'green-icon' && {
    background: 'transparent',
    color: COLORS.green[500],
    border: `1px solid ${COLORS.green[500]}`,
    '&.Mui-disabled': {
      color: COLORS.green[400],
      opacity: '30%'
    }
  }),
  ...(variants === 'red-icon' && {
    background: 'transparent',
    color: COLORS.red[500],
    border: `1px solid ${COLORS.red[500]}`,
    '&.Mui-disabled': {
      color: COLORS.red[400],
      opacity: '30%'
    }
  }),
  ...(rounded && {
    borderRadius: '32px'
  })
}));
export const Button: FC<ButtonStyledProps> = ({
  isLoading = false,
  isLoadingWithoutText = false,
  children,
  size,
  rounded = false,
  style,
  startIcon,
  endIcon,
  className,
  dark,
  variants = 'secondary',
  id,
  loaderIcon = <CircularProgress data-testid={`${id}-loader`} color="inherit" size={20} />,
  disabled,
  component,
  ...rest
}) => <StyledButton {...rest} className={className} id={id} data-testid={id} size={size} rounded={rounded} startIcon={isLoading ? loaderIcon : startIcon} endIcon={endIcon} dark={dark} disabled={disabled} style={style} variants={variants} component={component} onClick={e => {
  if (!isLoading && rest.onClick) rest.onClick(e);
}} data-sentry-element="StyledButton" data-sentry-component="Button" data-sentry-source-file="Button.tsx">
    {isLoadingWithoutText ? <CircularProgress color="inherit" size={20} id={`loader-${id}`} data-testid={`loader-${id}`} /> : children}
  </StyledButton>;
export default Button;